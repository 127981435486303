import {
  Badge,
  Box,
  ContentContainer,
  GridBox,
  StrokeButton,
  Text,
} from '@codecademy/gamut';
import { DiagonalBDense } from '@codecademy/gamut-patterns';
import styled from '@emotion/styled';

import { PausableImage } from '..';

const StyledPattern = styled(DiagonalBDense)`
  color: ${({ theme }) => theme.colors.yellow};
`;

const StyledBadge = styled(Badge)`
  display: inline-block;
  position: relative;
  top: -0.5rem;
`;

export const ProductFeatureShowcase: React.FC<{
  image: {
    src: string;
    alt: string;
  };
  title: React.ReactNode;
  description: React.ReactNode;
  titleBadge?: string;
  eyebrow?: React.ReactNode;
  cta?: {
    label: string;
    href?: string;
    onClick?: () => void;
  };
}> = ({ image, title, titleBadge, eyebrow, description, cta }) => (
  <Box bg="paleYellow" position="relative">
    <StyledPattern
      color="yellow"
      position="absolute"
      left={0}
      top={0}
      bottom={0}
      width="40%"
      pointerEvents="none"
      display={{ _: 'none', md: 'block' }}
    />
    <ContentContainer>
      <GridBox
        gridTemplateColumns={{ _: '1fr', md: '1fr 1fr' }}
        gap={{ _: 48, md: 96 }}
        py={{ _: 48, md: 64 }}
        alignItems="center"
      >
        <Box position="relative">
          <StyledPattern
            color="yellow"
            position="absolute"
            width="100vw"
            top={-48}
            left={{ _: -16, xs: -32, sm: -64 }}
            height="calc(50% + 48px)"
            pointerEvents="none"
            display={{ md: 'none' }}
          />
          <PausableImage src={image.src} alt={image.alt} />
        </Box>
        <Box>
          {eyebrow && <Box mb={8}>{eyebrow}</Box>}

          <Text as="h2" variant="title-xl">
            <Text mr={8} display="inline">
              {title}
            </Text>
            {titleBadge && (
              <StyledBadge variant="tertiary" fontSize={14}>
                {titleBadge}
              </StyledBadge>
            )}
          </Text>

          <Text mt={8}>{description}</Text>

          {cta && (
            <StrokeButton href={cta.href} onClick={cta.onClick} mt={16}>
              {cta.label}
            </StrokeButton>
          )}
        </Box>
      </GridBox>
    </ContentContainer>
  </Box>
);
